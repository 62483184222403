<template>
  <div>

    <div :class="['header', {'active': open}, {'scroll': scroll}]">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-row justify-content-center justify-content-lg-between align-items-center">
              <div class="logo mr-5 text-center">
                <a href="#" v-scroll-to="'#start'">
                  <img src="@/assets/img/logo.svg" alt="" class="w-100">
                </a>
              </div>
              <div>
                <nav>
                  <ul class="list-unstyled">
                    <li><a href="#start" v-scroll-to="'#start'" @click="open = false">START</a></li>
                    <li><a href="#mozliwosci" v-scroll-to="'#mozliwosci'" @click="open = false">WORLD ELITE MC<sup>®</sup></a></li>
                    <li><a href="#korzysci" v-scroll-to="'#korzysci'" @click="open = false">KORZYŚCI</a></li>
                    <li><a href="#w-zasiegu-reki" v-scroll-to="'#w-zasiegu-reki'" @click="open = false">W ZASIĘGU RĘKI</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hamburger-component">
        <div class="hamburger-bg"/>
        <div class="hamburger" @click="open = !open"><span/><span/><span/></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationComponent",
  data() {
    return {
      open: false,
      scroll: false,
    }
  },
  watch: {
    '$route': function () {
      this.open = false;
    }
  },
  mounted() {
    this.scrollEvent();
  },
  methods: {
    scrollEvent() {
      document.addEventListener('scroll', () => {
        this.scroll = window.scrollY > 100;
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.header {
  width: 100%;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .24);

  .logo {
    width: 100%;
    max-width: 275px;
    position: relative;
    z-index: 12;
  }

  nav {
    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        margin: 0;

        a {
          @extend .f20;
          @extend .open-sans;
          display: block;
          font-weight: 700;
          padding: 7px 0;
          margin: 0 15px;
          color: $cSec;
          transition: .2s;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: '';
            width: 0;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, 10px);
            background: $cSec;
            transition: .4s ease;
          }

          &:hover, &.router-link-exact-active {
            color: $cSec;
            text-decoration: none;

            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .hamburger-component {
    padding: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: none;

    .hamburger-bg {
      width: 200%;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 100%;
      background: red;
      transform: translate(40%, 50%);
      transition: 1s;
      box-shadow: 0 3px 32px 0 #262626;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  .hamburger {
    width: 40px;
    height: 19px;
    z-index: 99;
    cursor: pointer;
    display: none;
    position: relative;
    transition: .4s ease-out;

    span {
      width: 100%;
      height: 2px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      transition: .4s ease-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 8px;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }
  }

  &.active {
    .hamburger span {
      background: $cSec;

      &:nth-child(1) {
        transform: rotate(45deg) translate(8px, 4px);
      }

      &:nth-child(2) {
        width: 0;
        transform: translate(-18px, 0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -3px);
      }
    }
  }

  @media (max-width: 1300px) {
    padding: 20px 0;
    .products-navigation {
      display: none;
    }
    nav {
      width: 100vw;
      height: 100vh;
      padding-top: 20px;
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(3);
      transform-origin: 50% 50%;
      visibility: hidden;
      transition: .4s ease-out;
      pointer-events: none;
      z-index: 9999;

      ul {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        pointer-events: auto;

        li {
          display: block;
          text-align: center;

          a {
            background: transparent;
            padding: 5px 20px;
            margin: 5px 0;
            color: $cSec;
            display: inline-block;
            text-align: center;

            &::before {
              display: none;
            }
          }
        }
      }
    }
    .hamburger-component, .hamburger {
      display: block;
    }
    &.active {
      nav {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
      .hamburger-component {
        .hamburger-bg {
          width: 300vh;
          background: white;
          border-radius: 0;
          box-shadow: 0 0 0 0 #262626;
        }
      }
    }
  }
}

</style>
