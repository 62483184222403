import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/HomePage')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop - 100,
        behavior: 'smooth'
      })
    }
    return {x: 0, y: 0}
  },
  routes
})

export default router
