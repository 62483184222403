<template>
  <div class="main-container main" id="app">
    <main>
      <navigation-component/>
      <transition name="scale-fade" mode="out-in">
        <router-view/>
      </transition>
    </main>
    <footer-component/>
    <cookie-component/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import NavigationComponent from "@/components/NavigationComponent";
import FooterComponent from "@/components/FooterComponent";
import CookieComponent from "@/components/CookieComponent";
export default {
  components: {
    CookieComponent,
    FooterComponent,
    NavigationComponent
  },
}
</script>

<style lang="scss">
@import "src/assets/sass/app";
</style>